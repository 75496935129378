(function ($, undefined) {
    /**
     * Single post page.
     */
    $(function(){
        if ( !$( 'body.single-post').length) {
            // exit
            return false;
        }


    });

    /* slider header  */
    var hotNewsSlider = new Swiper('.hot-news-slider .swiper-container', {
        loop: true,
        navigation: {
            nextEl: '.hot-news-slider__nav_next',
            prevEl: '.hot-news-slider__nav_prev',
        },
    });
})(jQuery);
